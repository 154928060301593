<template>
  <div class="player">
    <div class="top">
      <!-- video -->
      <div class="video">
        <!--  -->
        <iframe
          :src="playing"
          scrolling="0"
          frameborder="0"
          width="100%"
          height="100%"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
        >
        </iframe>
      </div>
      <!-- 搜索框及集数组 -->
      <div class="group">
        <div class="searchInput">
          <inputSearch :inintWidth="inputSearch"></inputSearch>
        </div>
        <!-- 集数组 -->
        <div class="collection">
          <el-button
            v-for="v in group"
            :key="v"
            class="chapter"
            @click="selectPlay(v)"
            :class="{ selected: v == playChapter }"
          >
            {{ v }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- 信息描述 -->
    <div class="information">
      <div class="pic">
        <img
          :src="videoData.vodPic"
          alt="封面"
          v-if="videoData.vodPic.length > 0"
        />
        <img
          src="../assets/load.jpg"
          v-else-if="videoData.vodPic.length == 0 || videoData.vodPic == null"
          alt="封面"
        />
      </div>
      <!-- 视频信息 -->
      <div class="content">
        <p>{{ videoData.vodName }}</p>
        <p>
          <span style="color: rgb(128, 128, 128)">上映时间</span
          >{{ videoData.vodYear }}
        </p>
        <p>
          <span style="color: rgb(128, 128, 128)">上映地区</span
          >{{ videoData.vodArea }}
        </p>
        <p>
          <span style="color: rgb(128, 128, 128)">上次更新时间</span
          >{{ videoData.vodAddtime }}
        </p>
        <p id="videoContent">
          <span style="color: rgb(128, 128, 128)">简介</span
          >{{ videoContent }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from "vue";
import axios from "axios";
import inputSearch from "@/components/inputSearch.vue";

export default {
  name: "PlayViews",
  components: { inputSearch },
  setup() {
    var videoData = reactive({
      vodId: 0, 
      vodName: "", 
      vodTitle: 0, 
      vodPic: "",
      vodActor: "",
      vodYear: 0, 
      vodArea: "", 
      vodAddtime: "", 
      vodLetter: "", 
      vodLanguage: "",
    });
    const videoContent = ref(""); //简介信息
    var playData = []; //播放数据组
    var group = []; //集数组
    const vid = ref(localStorage.getItem("playId")); //获取播放id
    const playing = ref(0); //当前播放内容
    if (!localStorage.getItem("playChapter"))
      localStorage.setItem("playChapter", ""); //播放集数
    var playChapter = ref(localStorage.getItem("playChapter")); //当前播放集数
    console.log("localStorage", playChapter.value);

    let userAgent = navigator.userAgent; //当前设备信息
    const inputSearch = ref("width:80%;font-size:1rem"); //根据当前设备信息进行ui调整
    if (userAgent.indexOf("Mobile") !== -1) {
      inputSearch.value = "width:50%;font-size:1rem";
    }

    onMounted(() => {
      //根据id获取数据
      axios
        .get("https://www.emtanimation.fun:8080/selectVideoById", {
          params: {
            vid: vid.value,
          },
        })
        .then((response) => {
          if (response.data != null) {
            Object.assign(videoData, response.data);
            videoData.vodAddtime = timeUtis(videoData.vodAddtime);
          }
        });
      //根据id获取简介信息
      axios
        .get(
          "https://www.emtanimation.fun:8080/selectContent",
          {
            params: {
              vid: vid.value,
            },
          },
          setTimeout(5000)
        )
        .then((response) => {
          if (response.data != null) {
            //对简介信息进行过滤处理
            var decodedString = response.data
              .replace(/&lt;\/?(p|br)&gt;/g, "") 
              .replace(/&lt;/g, "")
              .replace(/&gt;/g, "")
              .replace(/&amp;nbsp;/g, " ")
              .replace(/<br\s*\/?>/gi, "")
              .replace(/<\/?p>/g, "");

            decodedString = decodedString.trim().replace(/\s+/g, " ");
            videoContent.value = decodedString.trim();
          }
        });
      getplay();
    });

    // 选择集数
    function selectPlay(v) {
      if (v !== "" && typeof v !== "undefined") {
        console.log(v);
        localStorage.setItem("playChapter", v); //将当前集数写入本地缓存
        let index = group.indexOf(v);
        console.log("str", group);
        playChapter.value = v;
        //获取指定集数的播放数据
        var str = playData[index];
        if (str == null) alert("emmm出错了，这边建议刷新或者重新进来");
        // 根据播放数据的格式进行有关处理
        if (str.slice(-5) == ".m3u8")
          playing.value = "https://lziplayer.com/?url=" + playData[index];
        else playing.value = playData[index];
        console.log(playing.value, "当前播放数据");
      }
    }

    //获取播放数据组以及集数组
    async function getplay() {
      try {
        //获取集数组
        await axios
          .get("https://www.emtanimation.fun:8080/getScore", {
            params: {
              vid: vid.value,
            },
          })
          .then((response) => {
            console.log("GETScore response", response.data);
            if (response.data != null && response.data.length > 0) {
              response.data.forEach((element) => {
                group.push(element);
              });
              console.log("集数组", group);
            }
          });
        //获取播放数据组
        await axios
          .get("https://www.emtanimation.fun:8080/getPlay", {
            params: {
              vid: vid.value,
            },
          })
          .then((response) => {
            if (response.data != null && response.data.length > 0) {
              playData.length = 0;
              playData = response.data;
              console.log("播放数据组response", response.data);
              if (playChapter.value == "") selectPlay(group[0]);
              else selectPlay(playChapter.value);
            }
          });
      } catch (error) {
        console.error("获取失败:", error);
      }
    }

    //时间处理
    function timeUtis(time) {
      let date = new Date(time * 1000); // js的date是ms计的
      // 拼接
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0"); // js的month是从0开始计数的
      let day = String(date.getDate()).padStart(2, "0");
      let formattedDate = year + "-" + month + "-" + day;
      // 返回更新时间
      return formattedDate;
    }

    return {
      videoData,
      videoContent,
      playData,
      group,
      playing,
      playChapter,
      inputSearch,
      selectPlay,
    };
  },
};
</script>

<style scoped lang="less">
.player {
  width: 90%;
  background: rgb(00, 00, 00);
  margin: 0 auto;

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24rem;

    .video {
      width: 50%;
      height: 20rem;
    }

    /*播放*/
    .playVideo {
      width: 50%;
      height: 50%;
    }

    /*包括集数组、输入框*/
    .group {
      width: 30%;
      height: 20rem;
      margin-left: 5rem;
      text-align: center;

      .collection {
        border: 0.3rem solid #989393;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        color: white;
        height: 15rem;
        overflow: auto;

        /**集数内容*/
        .chapter {
          padding: 0.3rem;
          height: 1rem;
          width: 4rem;
          cursor: pointer;
          background-color: #000;
          border: none;
          margin: 0.5rem 0;
          font-size: 1rem;
        }

        .selected {
          color: blue;
        }
      }
    }
  }

  /**视频信息**/
  .information {
    width: 100%;
    border: 0.2rem solid white;
    color: white;
    display: flex;
    justify-items: center;

    .pic {
      width: 10rem;

      img {
        width: 10rem;
      }
    }

    .content {
      margin-left: 1rem;

      #videoContent {
        height: 5rem;
        overflow: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .player {
    width: 100%;
    height: 85vh;
    background: rgba(00, 00, 00, 0.8);
    overflow: auto;

    /**上层内容**/
    .top {
      display: block;
      height: auto;
      padding-top: 2rem;

      .video {
        width: 90%;
        margin: 0 auto;
        border: 0.1rem solid white;
      }

      .group {
        width: 100%;
        margin-left: 0rem;
        height: auto;

        /**输入框*/
        .searchInput {
          width: 100%;
        }

        /**集数组**/
        .collection {
          width: 70%;
          margin: 0 auto;
          display: block;
          text-align: left;

          .chapter {
            height: 5rem;
            width: 5rem;
            font-size: 1.2rem;
            margin-left: 0.1rem;
          }
        }
      }
    }

    /**视频信息**/
    .information {
      font-size: 1.2rem;
      width: auto;
      margin-top: 3rem;

      .pic {
        display: flex;
      }
    }
  }
}
</style>
